import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
} from "reactstrap";
import {
  RenderIf,
  getQueryParam,
  setQueryParam,
  deleteQueryParam,
  createNotification,
} from "../config/utils";
import useConstructor from "../config/hooks/useConstructor";
import DroitController from "../config/apiUtils/DroitController";
import ProfilController from "../config/apiUtils/ProfilController";
import Grid from "../components/Grid";
import SelectEditor from "@inovua/reactdatagrid-community/SelectEditor";

export default function Profils(props) {
  const [profilToShow, setProfilToShow] = useState("-1");
  const [profils, setProfils] = useState([]);
  const [profil, setProfil] = useState({});
  const [modalites, setModalites] = useState([]);

  const columns = [
    {
      header: "Libellé",
      name: "libelletypedroit",
      minWidth: 250,
      defaultFlex: 1,
      suppressSizeToFit: true,
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      header: "Code",
      name: "codetypedroit",
      minWidth: 250,
      defaultFlex: 1,
      suppressSizeToFit: true,
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      header: "Description",
      name: "description",
      minWidth: 250,
      defaultFlex: 1,
      suppressSizeToFit: true,
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      header: "Modalité",
      name: "libelledroitmodalite",
      minWidth: 120,
      defaultFlex: 1,
      suppressSizeToFit: true,
      sortable: true,
      filter: true,
      editable: true,
      resizable: true,
      editor: SelectEditor,
      editorProps: {
        idProperty: "id",
        dataSource: modalites,
        collapseOnSelect: true,
      },
    },
  ];

  const gridStyle = { minHeight: 600, color: "#ffffff" };

  const defaultFilterValue = [
    {
      name: "libelletypedroit",
      type: "select",
      operator: "inlist",
      value: [],
    },
    { name: "codetypedroit", type: "string", operator: "contains", value: "" },
    { name: "description", type: "string", operator: "contains", value: "" },
    {
      name: "libelledroitmodalite",
      type: "string",
      operator: "contains",
      value: "",
    },
  ];

  const loadProfils = async () => {
    const res = await ProfilController.getProfils();
    res.unshift({ idprofil: -1, libelleprofil: "--" });
    setProfils(res);
  };

  useConstructor(async () => {
    await loadProfils();

    const resModalites = await DroitController.getDroitModalites();

    setModalites(
      resModalites.map((modalite) => {
        return {
          id: modalite.iddroitmodalite,
          label: modalite.libelledroitmodalite,
        };
      }),
    );

    if (getQueryParam("profil")) {
      setProfilToShow(getQueryParam("profil"));
    }
  });

  useEffect(() => {
    setProfil(profils.find((p) => p.idprofil == profilToShow));
  }, [profilToShow]);

  const renderProfilsOptions = () => {
    return profils.map((profil) => {
      return (
        <option key={profil.idprofil} value={profil.idprofil}>
          {profil.libelleprofil}
        </option>
      );
    });
  };

  const renderEmptyOption = () => {
    return (
      <Col
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card>
          <CardBody>
            Sélectionnez le profil que vous souhaitez consulter.
          </CardBody>
        </Card>
      </Col>
    );
  };

  const onEditComplete = async ({ value, rowId }) => {
    const droit = profil.droits.find((d) => d.idtypedroit === rowId);
    const idtypedroit = rowId;
    const modalite = value;

    const res = await ProfilController.updateModaliteTypeDroitProfil(
      profil.idprofil,
      idtypedroit,
      modalite,
    );

    if (res.status === 200) {
      createNotification(
        "success",
        "Succès",
        "Droit " + droit.libelletypedroit + " modifié avec succès",
      );

      const res = await ProfilController.getProfils();
      res.unshift({ idprofil: -1, libelleprofil: "--" });
      setProfils(res);
      setProfil(res.find((p) => p.idprofil == profilToShow));
    } else {
      createNotification(
        "error",
        "Erreur",
        "Erreur lors de la modification du droit " +
          droit.libelletypedroit +
          "...",
      );
    }
  };

  const renderGridDroits = () => {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>{profil?.libelleprofil}</CardTitle>
            </Col>
            <Col style={{ display: "none", flexDirection: "row-reverse" }}>
              <Button color="primary" onClick={props.onClickNewMateriel}>
                <i className="fa-solid fa-plus" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Grid
            idProperty="idtypedroit"
            columns={columns}
            emptyText="Aucune droit n'est disponible"
            dataSource={profil?.droits}
            style={gridStyle}
            onEditComplete={onEditComplete}
            defaultFilterValue={defaultFilterValue}
            pagination
            defaultLimit={100}
          />
        </CardBody>
      </Card>
    );
  };

  return (
    <>
      <Row style={{ marginBottom: 30 }}>
        <Col>
          <h2>Gestion des profils</h2>
        </Col>
        <Col md="3" style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Input
            type="select"
            className="select-input"
            name="profil"
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
            }}
            value={profilToShow}
            onChange={(event) => {
              if (event.target.value === "-1") {
                deleteQueryParam("profil");
              } else {
                setQueryParam("profil", event.target.value);
              }
              setProfilToShow(event.target.value);
            }}
          >
            {renderProfilsOptions()}
          </Input>
        </Col>
      </Row>
      <Row>
        <Col md="12" style={{ marginBottom: 30 }}>
          <RenderIf isTrue={profilToShow == -1}>{renderEmptyOption()}</RenderIf>
          <RenderIf isTrue={profilToShow != -1}>{renderGridDroits()}</RenderIf>
        </Col>
      </Row>
    </>
  );
}
