import { useEffect, useRef, useState } from "react";
import ExploitationController from "../config/apiUtils/ExploitationController";
import MillesimeController from "../config/apiUtils/MillesimeController";
import FormationController from "../config/apiUtils/FormationController";
import UtilisateurController from "../config/apiUtils/UtilisateurController";
import AuthService from "../config/AuthService";
import useDeviceDetect from "../config/hooks/useDeviceDetect";
import StoreContext from "./StoreContext";
import {
  createNotification,
  deleteQueryParam,
  getQueryParam,
} from "../config/utils";
import Layer from "../config/carto/Layer";
import { getExtentFeatures, getLayerById } from "../config/carto/utils";
import * as olEasing from "ol/easing";
import CartoController from "../config/apiUtils/CartoController";
import GeoJSON from "ol/format/GeoJSON";

export default function StoreProvider(props) {
  // Initialisation des states
  /* CERTIFICATIONS */

  const [certificationsIgp, setCertificationsIgp] = useState([]);
  const [certificationsExploitation, setCertificationsExploitation] = useState(
    [],
  );

  const [civilites, setCivilites] = useState([]);
  const [codesDroit, setCodesDroit] = useState(null);
  const [communes, setCommunes] = useState([]);
  const [commandes, setCommandes] = useState([]);
  const [contrats, setContrats] = useState([]);
  const [contratsExploitation, setContratsExploitation] = useState([]);
  const [conventions, setConventions] = useState([]);

  const [details, setDetails] = useState([]);
  const [droits, setDroits] = useState([]);

  const [exploitation, setExploitation] = useState({});
  const [exploitations, setExploitations] = useState([]);

  const [formations, setFormations] = useState([]);
  const [formationsMillesime, setFormationsMillesime] = useState([]);

  const [geometries, setGeometries] = useState([]);

  const [millesimeCourant, setMillesimeCourant] = useState({});
  const [millesime, setMillesime] = useState({
    idmillesime: null,
  });
  const [millesimes, setMillesimes] = useState([]);

  const [nbParcelles, setNbParcelles] = useState(0);
  const [nomUtilisateur, setNomUtilisateur] = useState("");

  const [organismesStockeur, setOrganismesStockeur] = useState([]);

  const [parcelles, setParcelles] = useState([]);
  const [parcellesExploitation, setParcellesExploitation] = useState([]);

  const [zonesProductionExploitation, setZonesProductionExploitation] =
    useState([]);

  /* PLAN DE PRODUCTION */

  const [planProductionParcelle, setPlanProductionParcelle] = useState([]);
  const [planProductionPrevisionnel, setPlanProductionPrevisionnel] = useState(
    [],
  );
  const [planProductionOrganismeStockeur, setPlanProductionOrganismeStockeur] =
    useState([]);

  const [bilanRecolteExploitation, setBilanRecolteExploitation] = useState([]);

  const [producteurs, setProducteurs] = useState([]);
  const [producteursMillesime, setProducteursMillesime] = useState([]);
  const [profilsUtilisateur, setProfilsUtilisateur] = useState([]);

  const [profils, setProfils] = useState([]);

  const [utilisateur, setUtilisateur] = useState({});

  const [zonesProduction, setZonesProduction] = useState([]);

  const [versions, setVersions] = useState([]);

  const [surfacesPotentielles, setSurfacesPotentielles] = useState([]);

  const [silos, setSilos] = useState([]);

  /* STATES DES REFERENTIELS */

  const [cultures, setCultures] = useState([]);
  const [typesProduits, setTypesProduits] = useState([]);
  const [produits, setProduits] = useState([]);
  const [typesMateriels, setTypesMateriels] = useState([]);
  const [materiels, setMateriels] = useState([]);
  const [typesInterventions, setTypesInterventions] = useState([]);
  const [typesDocuments, setTypesDocuments] = useState([]);
  const [typesFormations, setTypesFormations] = useState([]);
  const [typesProduction, setTypesProduction] = useState([]);
  const [etatsValidationAdministrative, setEtatsValidationAdministrative] =
    useState([]);
  const [typesVarietes, setTypesVarietes] = useState([]);
  const [varietes, setVarietes] = useState([]);
  const [statutsJuridique, setStatutsJuridique] = useState([]);
  const [matieresActives, setMatieresActives] = useState([]);
  const [typesSol, setTypesSol] = useState([]);
  const [typesSilo, setTypesSilo] = useState([]);

  /* CARTOGRAPHIE */

  const [layers, setLayers] = useState([]);
  const [geolocationEnabled, setGeolocationEnabled] = useState(false);

  const [parcellaire, setParcellaire] = useState([]);
  const [parcelleSelected, setParcelleSelected] = useState(null);

  const { isMobile } = useDeviceDetect();

  const mapRef = useRef();
  const parcelleAssocieeSelecterRef = useRef();

  const getLayer = (codeCouche) => {
    return layers.find((couche) => couche.getCodeCouche() === codeCouche);
  };

  const getParcellaireLayer = () => {
    return getLayer(Layer.codeCouche.PARCELLAIRE_EXPLOITATION);
  };

  const getRpgLayer = () => {
    return getLayer(Layer.codeCouche.RPG_ANONYME);
  };

  const toggleGeolocation = (active) => {
    setGeolocationEnabled(active);
    getLayerById(mapRef, "GEOLOCATION").setVisible(active);
  };

  const zoomMap = (features, options, ref = mapRef) => {
    const interval = setInterval(() => {
      if (ref.current) {
        ref.current.getView().fit(
          getExtentFeatures(features),
          options ?? {
            duration: 2000,
            easing: olEasing.easeOut,
            padding: [10, 10, 10, 10],
          },
        );
        clearInterval(interval); // this line is to stop interval once mapRef.current is defined
      }
    }, 100);
  };

  const zoomLocation = () => {
    if (
      getLayerById(mapRef, "GEOLOCATION")
        .getSource()
        .getFeatures()[0]
        .getGeometry()
    ) {
      zoomMap(getLayerById(mapRef, "GEOLOCATION").getSource().getFeatures(), {
        duration: 2000,
        easing: olEasing.easeOut,
        padding: [10, 10, 10, 10],
        maxZoom: 18,
      });
    } else {
      createNotification(
        "info",
        "Localisation en cours de récupération, veuillez patienter...",
      );
    }
  };

  const zoomEmprise = () => {
    zoomMap(getParcellaireLayer().getFeatures());
  };

  const loadFicheExploitation = async (millesime) => {
    const fiche = await ExploitationController.getFicheExploitation(millesime);
    if (fiche.status !== 500) {
      setExploitation(fiche.exploitation);
      setConventions(fiche.conventions);
      setContratsExploitation(fiche.contrats);
      setParcellesExploitation(fiche.parcelles);
      setProducteurs(fiche.producteurs);
      setSurfacesPotentielles(fiche.surfaces);
      return fiche;
    }
  };

  const loadDernierDossier = async () => {
    const resDernierDossier =
      await ExploitationController.getDernierDossierUtilisateur();
    setExploitation(resDernierDossier);
    return resDernierDossier;
  };

  const isLoaded = () => {
    if (
      nomUtilisateur !== "" &&
      millesimes.length > 0 &&
      millesime !== {} &&
      codesDroit !== null &&
      exploitation !== {}
    ) {
      return true;
    }
    return false;
  };

  const updateFormationsMillesime = async () => {
    const resFormations = await FormationController.getFormations(
      millesime.idmillesime,
    );
    setFormationsMillesime(resFormations);
  };

  const hasDroits = (codetypedroit, iddroitmodalite) => {
    var hasDroit = false;
    droits.forEach((droit) => {
      if (
        droit.codetypedroit === codetypedroit &&
        droit.iddroitmodalite >= iddroitmodalite
      ) {
        hasDroit = true;
      }
    });
    return hasDroit;
  };

  const loadData = async () => {
    const resProfils = await UtilisateurController.getProfilsUtilisateur();
    localStorage.removeItem("profils");
    localStorage.setItem("profils", JSON.stringify(resProfils));
    setProfilsUtilisateur(resProfils);

    const resDroits = await UtilisateurController.getDroitsUtilisateur();
    localStorage.removeItem("droits");
    localStorage.setItem("droits", JSON.stringify(resDroits));
    setDroits(resDroits);

    MillesimeController.getMillesimes().then((res) => {
      setMillesimes(res);
    });

    const millesimeUtilisateur =
      await MillesimeController.getDernierMillesimeUtilisateur();
    setMillesime(millesimeUtilisateur);

    const resUtilisateur = await UtilisateurController.getUserInfo();
    setNomUtilisateur(resUtilisateur.prenom + " " + resUtilisateur.nom);

    if (resUtilisateur.isProducteur) {
      setMillesimeCourant(await MillesimeController.getMillesimeCourant());
    }

    const resFiche = await loadFicheExploitation(
      millesimeUtilisateur.idmillesime,
    );

    if (getQueryParam("loggedin") == "true") {
      createNotification("success", "Succès", "Connecté avec succès");
      deleteQueryParam("loggedin");

      if (resUtilisateur.isProducteur) {
        if (resFiche.contrats.length === 0) {
          createNotification(
            "warning",
            "Contrat manquant",
            "Vous n'avez pas de contrat saisi pour la campagne 2024.",
            10000,
          );
        }
        if (resFiche.surfaces.length === 0) {
          createNotification(
            "warning",
            "Intention de semis manquante",
            "Vous n'avez pas d'intention de semis saisie pour la campagne 2024.",
            10000,
          );
        }
      }
    }

    setCodesDroit(resDroits.map((droit) => droit.codetypedroit));
    setUtilisateur(resUtilisateur);
  };

  const loadDataParcellaireCarto = async (force) => {
    const tmpLayers = await CartoController.getLayersCatalogue(
      Layer.catalogue.PARCELLAIRE_EXPLOITATION,
    );

    const tmp = tmpLayers.map((layer) => new Layer(layer, mapRef, force));

    const parcellaire =
      await ExploitationController.getParcellesByIdexploitation(
        exploitation.idexploitation,
        millesime.idmillesime,
      );

    const features = parcellaire
      .filter((parcelle) => parcelle.geometrie != null)
      .map((parcelle) => ({
        ...parcelle.geometrie,
      }))
      .map((geom) => {
        return new GeoJSON().readFeatures(geom)[0];
      });
    tmp
      .find(
        (couche) =>
          couche.getCodeCouche() === Layer.codeCouche.PARCELLAIRE_EXPLOITATION,
      )
      .addFeatures(features);

    setLayers([...tmp]);
    setParcellaire([...parcellaire]);
  };

  // Récupération des données au lancement de l'application
  useEffect(() => {
    const load = async () => {
      try {
        if (AuthService.isLoggedIn()) {
          await loadData();
        }
      } catch (e) {
        createNotification("error", "Erreur", e);
      }
    };
    load();
  }, []);

  return (
    <StoreContext.Provider
      value={{
        loadData,
        isLoaded,

        userDevice: {
          isMobile,
        },

        carto: {
          mapRef,
          parcelleAssocieeSelecterRef,

          layers,
          setLayers,

          parcellaire,
          setParcellaire,

          parcelleSelected,
          setParcelleSelected,

          geolocationEnabled,
          setGeolocationEnabled,

          getLayer,
          getParcellaireLayer,
          getRpgLayer,

          toggleGeolocation,

          zoomMap,
          zoomLocation,
          zoomEmprise,

          loadDataParcellaireCarto,

          typeLayers: {
            WMS: 1,
            TMS: 2,
            WMTS: 3,
            WMS_VECTOR: 4,
            METIER: 5,
            WFS: 6,
            GEOJSON: 7,
            OSM: 8,
          },

          urlCarto: {
            pac: {
              url: "https://wxs.ign.fr/ortho/geoportail/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&TILEMATRIXSET=PM&TILEMATRIX={z}&TILECOL={x}&TILEROW={y}&STYLE=normal&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&FORMAT=image/jpeg",
              libelle: "Fond carto PAC",
              attribution: "attributions",
            },
            ign: {
              url: "https://wxs.ign.fr/decouverte/geoportail/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM&STYLE=normal&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&FORMAT=image/png",
              libelle: "Fond carto IGN",
              attribution: "attributions",
            },
            osm: {
              url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
              libelle: "Fond carto OSM",
              attribution: "attributions",
            },
            mtbmap: {
              url: "http://tile.mtbmap.cz/mtbmap_tiles/{z}/{x}/{y}.png",
              libelle: "Fond carto mtbmap",
              attribution: "attributions",
            },
          },
        },

        utilisateur: {
          codesDroit,
          droits,
          informations: utilisateur,
          setUtilisateur,
          nomUtilisateur,
          profilsUtilisateur,
          hasDroits,
        },

        certifications: {
          certificationsIgp,
          setCertificationsIgp,

          certificationsExploitation,
          setCertificationsExploitation,
        },

        commandes,
        setCommandes,

        communes,
        setCommunes,

        civilites,
        setCivilites,

        surfacesPotentielles,
        setSurfacesPotentielles,

        silos,
        setSilos,

        millesimes,
        setMillesimes,

        millesime,
        setMillesime,

        millesimeCourant,

        parcelles,
        setParcelles,

        geometries,
        setGeometries,

        producteursMillesime,
        setProducteursMillesime,

        formations: {
          formations,
          setFormations,

          formationsMillesime,
          setFormationsMillesime,
          updateFormationsMillesime,
        },

        exploitations,
        setExploitations,

        exploitation: {
          informations: exploitation,
          setExploitation,

          loadDernierDossier,

          zonesProduction: zonesProductionExploitation,
          setZonesProduction: setZonesProductionExploitation,

          producteurs,
          setProducteurs,

          parcellesExploitation,
          setParcellesExploitation,
        },

        planProduction: {
          planProductionParcelle,
          setPlanProductionParcelle,

          planProductionPrevisionnel,
          setPlanProductionPrevisionnel,

          planProductionOrganismeStockeur,
          setPlanProductionOrganismeStockeur,

          bilanRecolteExploitation,
          setBilanRecolteExploitation,
        },

        profils: {
          profils,
          setProfils,
        },

        zonesProduction,
        setZonesProduction,

        contrats,
        setContrats,

        contratsExploitation,
        setContratsExploitation,

        detailContratsExploitation: {
          nbParcelles,
          setNbParcelles,
          details,
          setDetails,
        },

        organismesStockeur,
        setOrganismesStockeur,

        conventions,
        setConventions,

        referentiels: {
          etatsValidationAdministrative,
          setEtatsValidationAdministrative,

          cultures,
          setCultures,

          materiels,
          setMateriels,

          produits,
          setProduits,

          matieresActives,
          setMatieresActives,

          typesDocuments,
          setTypesDocuments,

          typesFormations,
          setTypesFormations,

          typesInterventions,
          setTypesInterventions,

          typesMateriels,
          setTypesMateriels,

          typesProduits,
          setTypesProduits,

          typesProduction,
          setTypesProduction,

          typesVarietes,
          setTypesVarietes,

          typesSol,
          setTypesSol,

          typesSilo,
          setTypesSilo,

          varietes,
          setVarietes,

          statutsJuridique,
          setStatutsJuridique,
        },

        versions: {
          versions,
          setVersions,
        },
      }}
    >
      {props.children}
    </StoreContext.Provider>
  );
}
