import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import StoreContext from "../../context/StoreContext";
import { useContext, useEffect, useState } from "react";
import useConstructor from "../../config/hooks/useConstructor";
import ExploitationController from "../../config/apiUtils/ExploitationController";
import OrganismeStockeurController from "../../config/apiUtils/OrganismeStockeurController";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import CloseButton from "../../components/Buttons/CloseButton";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import { RenderIf } from "../../config/utils";
import Constantes from "../../config/constantes";
import DeleteButton from "../../components/Buttons/DeleteButton";

export default function FormUpdateIntention(props) {
  const store = useContext(StoreContext);
  const [modal, setModal] = useState(false);
  const [datesurfacepotentielle, setDatesurfacepotentielle] = useState(
    props.intention.datesurfacepotentielle,
  );
  const [surfacepotentielleprintemps, setSurfacepotentielleprintemps] =
    useState(props.intention.surfacepotentielleprintemps);
  const [surfacepotentielleautomne, setSurfacepotentielleautomne] = useState(
    props.intention.surfacepotentielleautomne,
  );
  const [commentaire, setCommentaire] = useState(props.intention.commentaire);
  const [exploitation, setExploitation] = useState(
    props.intention.exploitation,
  );
  const [abandon, setAbandon] = useState(props.intention.abandon);
  const [idetatvalidationadministrative, setIdetatvalidationadministrative] =
    useState(props.intention.idetatvalidationadministrative);
  const [validationadministrative, setValidationadministrative] = useState(
    props.intention.valide,
  );
  const [idreftypeproduction, setIdreftypeproduction] = useState(
    props.intention.idreftypeproduction,
  );
  const [idorganismestockeur, setIdorganismestockeur] = useState(
    props.intention.idorganismestockeur,
  );
  const [loading, setLoading] = useState(false);

  const renderOrganismesStockeursOptions = () => {
    return store.organismesStockeur.map((organisme, key) => {
      return (
        <option key={key} value={organisme.idorganismestockeur}>
          {organisme.nomorganismestockeur}
        </option>
      );
    });
  };

  const renderTypesProductionOptions = () => {
    return store.referentiels.typesProduction.map((type, key) => {
      return (
        <option key={key} value={type.idreftypeproduction}>
          {type.libelle}
        </option>
      );
    });
  };

  const renderEtatsValidationAdministrativeOptions = () => {
    return store.referentiels.etatsValidationAdministrative.map((etat, key) => {
      return (
        <option key={key} value={etat.idetatvalidationadministrative}>
          {etat.libelle}
        </option>
      );
    });
  };

  const reloadIntention = () => {
    setCommentaire(props.intention.commentaire);
    setSurfacepotentielleprintemps(props.intention.surfacepotentielleprintemps);
    setSurfacepotentielleautomne(props.intention.surfacepotentielleautomne);
    setDatesurfacepotentielle(props.intention.datesurfacepotentielle);
    setExploitation(props.intention.exploitation);
    setAbandon(props.intention.abandon);
    setIdetatvalidationadministrative(
      props.intention.idetatvalidationadministrative,
    );
    setIdorganismestockeur(props.intention.idorganismestockeur);
    if (props.intention.valide) {
      setValidationadministrative(props.intention.valide);
    } else {
      if (store.utilisateur.profilsUtilisateur[0].estorganismestockeur) {
        if (
          props.intention.idetatvalidationadministrative ==
          Constantes.etatsValidationAdministrative.valideOS
        ) {
          setValidationadministrative(true);
        } else {
          setValidationadministrative(false);
        }
      } else {
        if (
          props.intention.idetatvalidationadministrative ==
          Constantes.etatsValidationAdministrative.valideAPGMB
        ) {
          setValidationadministrative(true);
        } else {
          setValidationadministrative(false);
        }
      }
    }
    setIdreftypeproduction(props.intention.idreftypeproduction);
  };

  useConstructor(async () => {
    setLoading(true);
    store.setOrganismesStockeur(
      await OrganismeStockeurController.getOrganismesStockeur(),
    );
    store.referentiels.setTypesProduction(
      await ReferentielController.getTypesProduction(true),
    );
    store.referentiels.setEtatsValidationAdministrative(
      await ReferentielController.getEtatsValidationAdministrative(),
    );
    reloadIntention();
    setLoading(false);
  });

  useEffect(() => {
    reloadIntention();
  }, [props.intention]);

  useConstructor(async () => {
    const resExploitation = await ExploitationController.getExploitations();
    store.setExploitations(resExploitation);
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const intention = {
      surfacepotentielleprintemps: surfacepotentielleprintemps,
      surfacepotentielleautomne: surfacepotentielleautomne,
      commentaire: commentaire,
      idorganismestockeur: idorganismestockeur,
      datesurfacepotentielle: datesurfacepotentielle,
      abandon: abandon,
      idetatvalidationadministrative: idetatvalidationadministrative,
      idreftypeproduction: idreftypeproduction,
      valide: validationadministrative,
    };
    await props.onClickUpdate(intention);
    setLoading(false);
  };

  const renderModal = () => {
    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader>Supprimer un formulaire utilisateur</ModalHeader>
        <ModalBody>
          Vous demandez la suppression de l'intention de semis de{" "}
          <b>{exploitation}</b>
          .<br />
          <br />
          Confirmez-vous cette demande de suppression ?
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            autoFocus={true}
            onClick={async () => {
              await props.onClickDelete();
              setModal(!modal);
            }}
          >
            Confirmer
          </Button>{" "}
          <Button color="secondary" onClick={() => setModal(!modal)}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <>
      {renderModal()}
      <Card>
        <CardHeader>
          <CardTitle tag={"h5"}>Modifier l'intention</CardTitle>
          <CloseButton onClick={props.hideFormUpdateIntention} />
          <CardSubtitle tag={"h6"}>
            Pour ajouter une intention de semis, remplissez les informations
            suivantes. Tous les champs étoilés sont requis.
          </CardSubtitle>
        </CardHeader>
        <CardBody>
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <Form onSubmit={onSubmit}>
              <Row style={{ marginTop: 20 }}>
                <Col md="4">
                  <FormGroup>
                    <Label for="datesurfacepotentielle">Date</Label>
                    <Input
                      type="date"
                      name="datesurfacepotentielle"
                      value={datesurfacepotentielle}
                      onChange={(event) =>
                        setDatesurfacepotentielle(event.target.value)
                      }
                      disabled={
                        (store.utilisateur.profilsUtilisateur[0]
                          .estorganismestockeur &&
                          (props.intention.idetatvalidationadministrative ===
                            3 ||
                            props.intention.idetatvalidationadministrative ===
                              4)) ||
                        props.intention.idetatvalidationadministrative === 4
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label for="idexploitation">Exploitation</Label>
                    <Input
                      type="text"
                      name="exploitation"
                      value={exploitation}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label for="idorganismestockeur">Organisme stockeur</Label>
                    <Input
                      type="select"
                      className="select-input"
                      name="idorganismestockeur"
                      placeholder="Organisme stockeur"
                      disabled={
                        ![
                          "ADMINISTRATEUR",
                          "RESPO_ADMIN",
                          "CONSEILLER",
                        ].includes(
                          store.utilisateur?.profilsUtilisateur[0]?.codeprofil,
                        ) ||
                        props.intention.idetatvalidationadministrative === 4
                      }
                      value={idorganismestockeur}
                      onChange={(e) => setIdorganismestockeur(e.target.value)}
                    >
                      {renderOrganismesStockeursOptions()}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col md="4">
                  <FormGroup>
                    <Label for="surfacepotentielleprintemps">
                      Surface potentielle printemps (Ha)
                    </Label>
                    <Input
                      type="text"
                      name="surfacepotentielleprintemps"
                      placeholder="Surface potentielle printemps (Ha)"
                      value={surfacepotentielleprintemps}
                      onChange={(event) =>
                        setSurfacepotentielleprintemps(event.target.value)
                      }
                      disabled={
                        (store.utilisateur.profilsUtilisateur[0]
                          .estorganismestockeur &&
                          (props.intention.idetatvalidationadministrative ===
                            3 ||
                            props.intention.idetatvalidationadministrative ===
                              4)) ||
                        props.intention.idetatvalidationadministrative === 4
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label for="surfacepotentielleautomne">
                      Surface potentielle automne (Ha)
                    </Label>
                    <Input
                      type="text"
                      name="surfacepotentielleautomne"
                      placeholder="Surface potentielle automne (Ha)"
                      value={surfacepotentielleautomne}
                      onChange={(event) =>
                        setSurfacepotentielleautomne(event.target.value)
                      }
                      disabled={
                        (store.utilisateur.profilsUtilisateur[0]
                          .estorganismestockeur &&
                          (props.intention.idetatvalidationadministrative ===
                            3 ||
                            props.intention.idetatvalidationadministrative ===
                              4)) ||
                        props.intention.idetatvalidationadministrative === 4
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label for="idreftypeproduction">Type de production</Label>
                    <Input
                      type="select"
                      className="select-input"
                      name="idreftypeproduction"
                      placeholder="Type de production"
                      value={idreftypeproduction}
                      onChange={(e) => setIdreftypeproduction(e.target.value)}
                      disabled={
                        (store.utilisateur.profilsUtilisateur[0]
                          .estorganismestockeur &&
                          (props.intention.idetatvalidationadministrative ===
                            3 ||
                            props.intention.idetatvalidationadministrative ===
                              4)) ||
                        props.intention.idetatvalidationadministrative === 4
                      }
                    >
                      {renderTypesProductionOptions()}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <RenderIf
                  isTrue={
                    store.utilisateur.profilsUtilisateur[0].codeprofil ===
                    "ADMINISTRATEUR"
                  }
                >
                  <Col md="4">
                    <FormGroup>
                      <Label for="idetatvalidationadministrative">
                        Validation administrative
                      </Label>
                      <Input
                        type="select"
                        className="select-input"
                        name="idetatvalidationadministrative"
                        value={idetatvalidationadministrative}
                        onChange={(event) =>
                          setIdetatvalidationadministrative(event.target.value)
                        }
                        disabled={
                          (store.utilisateur.profilsUtilisateur[0]
                            .estorganismestockeur &&
                            (props.intention.idetatvalidationadministrative ===
                              3 ||
                              props.intention.idetatvalidationadministrative ===
                                4)) ||
                          props.intention.idetatvalidationadministrative === 4
                        }
                      >
                        {renderEtatsValidationAdministrativeOptions()}
                      </Input>
                    </FormGroup>
                  </Col>
                </RenderIf>
                <Col
                  md={
                    store.utilisateur.profilsUtilisateur[0].codeprofil ===
                    "ADMINISTRATEUR"
                      ? "2"
                      : "4"
                  }
                >
                  <FormGroup>
                    <Label for="abandon">Abandonné</Label>
                    <Input
                      type="select"
                      className="select-input"
                      name="abandon"
                      value={abandon}
                      onChange={(event) => setAbandon(event.target.value)}
                      disabled={
                        store.utilisateur.profilsUtilisateur[0].codeprofil !==
                        "ADMINISTRATEUR"
                      }
                    >
                      <option value={true}>OUI</option>
                      <option value={false}>NON</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col
                  md={
                    store.utilisateur.profilsUtilisateur[0].codeprofil ===
                    "ADMINISTRATEUR"
                      ? "6"
                      : "8"
                  }
                >
                  <FormGroup>
                    <Label for="commentaire">Commentaire</Label>
                    <Input
                      type="textarea"
                      name="commentaire"
                      placeholder="Commentaire"
                      value={commentaire}
                      onChange={(event) => setCommentaire(event.target.value)}
                      disabled={
                        (store.utilisateur.profilsUtilisateur[0]
                          .estorganismestockeur &&
                          (props.intention.idetatvalidationadministrative ===
                            3 ||
                            props.intention.idetatvalidationadministrative ===
                              4)) ||
                        props.intention.idetatvalidationadministrative === 4
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <RenderIf
                isTrue={
                  store.utilisateur.profilsUtilisateur[0].codeprofil !==
                  "ADMINISTRATEUR"
                }
              >
                <Row style={{ marginBottom: 10 }}>
                  <Col>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="validationadministrative"
                        checked={validationadministrative}
                        onChange={(e) =>
                          setValidationadministrative(e.target.checked)
                        }
                      />{" "}
                      Validé administrativement
                    </Label>
                  </Col>
                </Row>
              </RenderIf>
              <Row>
                <Col md="6">
                  <ValidateButton style={{ marginTop: 15, marginRight: 15 }} />
                  <CancelButton
                    style={{ marginTop: 15, marginRight: 15 }}
                    onClick={(event) => {
                      event.preventDefault();
                      props.hideFormUpdateIntention();
                    }}
                  />
                  <RenderIf
                    isTrue={store.utilisateur.hasDroits(
                      "GESTION_INTENTIONS_SEMIS",
                      40,
                    )}
                  >
                    <DeleteButton
                      style={{ marginTop: 15 }}
                      onClick={(event) => {
                        event.preventDefault();
                        setModal(true);
                      }}
                    />
                  </RenderIf>
                </Col>
              </Row>
            </Form>
          </RenderIf>
        </CardBody>
      </Card>
    </>
  );
}
