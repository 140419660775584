import APIController from "../APIController";

class CotisationEditionController extends APIController {
  constructor() {
    super("/cotisations/edition");
  }

  async updateMontantpayettc(idcotisation, montantpayettc) {
    return await this.putRequest(
      `/montantpayettc?idcotisation=${idcotisation}&montantpayettc=${montantpayettc}`,
    );
  }

  async updatePaye(idcotisation, paye) {
    return await this.putRequest(
      `/paye?idcotisation=${idcotisation}&paye=${paye}`,
    );
  }

  async updateDatePaye(idcotisation, datepaye) {
    return await this.putRequest(
      `/datepaye?idcotisation=${idcotisation}${
        datepaye ? "&datepaye=" + datepaye : ""
      }`,
    );
  }
}

export default new CotisationEditionController();
