import APIController from "../APIController";

class SurfacePotentielleController extends APIController {
  constructor() {
    super("/surfacepotentielle");
  }

  async getSurfacesPotentielles(millesime) {
    return await this.getRequest(`/millesime/${millesime}`);
  }

  async getSurfacesPotentiellesExploitation(idexploitation, millesime) {
    return await this.getRequest(
      `/exploitation/${idexploitation}/millesime/${millesime}`,
    );
  }

  async insertSurfacePotentielle(surfacesPotentielle) {
    return await this.postRequest("/", surfacesPotentielle);
  }

  async updateSurfacePotentielle(idsurfacepotentielle, surfacesPotentielle) {
    return await this.putRequest(
      `/${idsurfacepotentielle}`,
      surfacesPotentielle,
    );
  }

  async deleteSurfacePotentielle(idsurfacepotentielle) {
    return await this.deleteRequest(`/${idsurfacepotentielle}`);
  }
}

export default new SurfacePotentielleController();
