import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Grid from "../../components/Grid";
import {
  columns,
  gridStyle,
} from "../../components/Grids/Cotisation/cotisation";
import { useNavigate } from "react-router-dom";
import { convertToCSV, downloadCsv, RenderIf } from "../../config/utils";
import CsvButton from "../../components/Buttons/CsvButton";
import moment from "moment";

export default function TableCotisation(props) {
  const navigate = useNavigate();

  const onRowClick = async (rowIdx) => {
    navigate(`/app/contrat?id=${rowIdx.data.idcontrat}`);
  };

  const getColumns = () => {
    const tmp = {};
    columns.forEach((col) => {
      tmp[col.name] = col.name === "prixcotisation" ? "Montant HT" : col.header;
    });
    return tmp;
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag={"h5"}>Liste des cotisations</CardTitle>
          </Col>
          <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
            <RenderIf isTrue={props.cotisations.length > 0}>
              <CsvButton
                color="primary"
                onClick={() => {
                  downloadCsv(
                    convertToCSV(props.cotisations, ";", getColumns()),
                    "export_cotisations_" + moment().format("YYYY-MM-DD"),
                  );
                }}
              />
            </RenderIf>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Grid
          enableCellSelect={true}
          idProperty="idrefcotisation"
          emptyText="Aucune cotisation renseignée"
          columns={columns}
          dataSource={props.cotisations}
          style={gridStyle}
          onRowClick={onRowClick}
          pagination
          defaultLimit={50}
        />
      </CardBody>
    </Card>
  );
}
