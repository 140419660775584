import SelectEditor from "@inovua/reactdatagrid-community/SelectEditor";
import DateEditor from "@inovua/reactdatagrid-community/DateEditor";
import AuthService from "../../../config/AuthService";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { dateFilterEditorProps } from "../../../config/utils";

export const columns = [
  {
    header: "Numéro",
    name: "numeroannexe",
    minWidth: 180,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) =>
      value == null ? "Contrat initial" : "Annexe n° " + value,
  },
  {
    header: "Surface",
    name: "surface",
    type: "number",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => {
      if (value) {
        return value.toFixed(2) + " Ha";
      }
      return "0 Ha";
    },
  },
  {
    header: "Montant",
    name: "prixcotisation",
    type: "number",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => {
      if (value) {
        return (value * 1.2).toFixed(2) + " € TTC";
      }
      return "0 € TTC";
    },
  },
  {
    header: "Montant payé",
    name: "montantpayettc",
    type: "number",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_COTISATION", 30),
    render: ({ value }) => {
      if (value) {
        return value.toFixed(2) + " € TTC";
      }
      return "0 € TTC";
    },
  },
  {
    header: "Reste à payer",
    name: "resteapayer",
    type: "number",
    minWidth: 150,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => value.toFixed(2) + " € TTC",
  },
  {
    header: "Date de paiement",
    name: "datepaye",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_COTISATION", 30),
    editor: DateEditor,
    dateFormat: "YYYY-MM-DD",
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
    render: ({ value }) => value ?? "Non renseigné",
  },
  {
    header: "Payée",
    name: "paye",
    cellStyle: { textAlign: "center" },
    minWidth: 170,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit("GESTION_COTISATION", 30),
    editor: SelectEditor,
    editorProps: {
      idProperty: "id",
      dataSource: [
        { id: true, label: "OUI", nomId: "paye" },
        { id: false, label: "NON", nomId: "paye" },
      ],
      collapseOnSelect: true,
    },
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = "#d1e7dd"; // SUCCESS
        cellProps.style.color = "#0f5132"; // SUCCESS
        return "OUI";
      }
      cellProps.style.background = "#f9d7da"; // DANGER
      cellProps.style.color = "#842029"; // DANGER
      return "NON";
    },
  },
];

export const gridStyle = { minHeight: 350, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "nomparcelle", type: "string", operator: "contains", value: "" },
  { name: "nomcommune", type: "string", operator: "contains", value: "" },
  { name: "libellevariete", type: "string", operator: "contains", value: "" },
  {
    name: "libelletypesol",
    type: "select",
    operator: "inlist",
    value: [],
  },
  { name: "quantitesemence", type: "float", operator: "contains", value: "" },
  {
    name: "surfaceengageeprintemps",
    type: "float",
    operator: "contains",
    value: "",
  },
  {
    name: "surfaceengageeautomne",
    type: "float",
    operator: "contains",
    value: "",
  },
  { name: "surfaceengagee", type: "float", operator: "contains", value: "" },
  { name: "surfaceprintemps", type: "float", operator: "contains", value: "" },
  { name: "surfaceautomne", type: "float", operator: "contains", value: "" },
  { name: "quantitelivree", type: "float", operator: "contains", value: "" },
  { name: "idilot", type: "string", operator: "contains", value: "" },
  { name: "surface", type: "string", operator: "contains", value: "" },
];
