import APIController from "../APIController";

class ContratController extends APIController {
  constructor() {
    super("/contrat");
  }

  async getContratMillesime(millesime) {
    return await this.getRequest(`/?millesime=${millesime}`);
  }

  async getContratDetail(idcontrat) {
    return await this.getRequest(`/${idcontrat}`);
  }

  async insertContrat(contrat) {
    return await this.postRequest("/", contrat);
  }

  async updateContrat(idcontrat, contrat) {
    return await this.putRequest(`/${idcontrat}`, contrat);
  }

  async addNewAnnexeOld(idcontrat, nbparcelles) {
    return await this.postRequest(
      `/${idcontrat}/annexeold?nbparcelles=${nbparcelles}`,
    );
  }

  async addNewAnnexe(idcontrat, formulaire) {
    return await this.postRequest(`/${idcontrat}/annexe`, formulaire);
  }

  async validerAnnexe(idcontrat, idannexe) {
    return await this.putRequest(`/${idcontrat}/annexe/${idannexe}/validation`);
  }

  async getAnnexes(idcontrat) {
    return await this.getRequest(`/${idcontrat}/annexe`);
  }

  async getAnnexeById(idcontrat, idannexe) {
    return await this.getRequest(`/${idcontrat}/annexe/${idannexe}`);
  }

  async completerContrat(idcontrat, formulaire) {
    return await this.postRequest(`/${idcontrat}/completer`, formulaire);
  }

  async deleteParcelleContrat(idcontrat, idparcelle) {
    return await this.deleteRequest(`/${idcontrat}/parcelle/${idparcelle}`);
  }

  async signerContrat(idcontrat) {
    return await this.putRequest(`/${idcontrat}/signer`);
  }
}

export default new ContratController();
