import Section from "../Section";
import {
  createNotification,
  deleteQueryParam,
  getQueryParam,
  RenderIf,
  setQueryParam,
} from "../../../../config/utils";
import StoreContext from "../../../../context/StoreContext";
import { useContext, useState } from "react";
import { Accordion, Alert, Col, Collapse, Row } from "reactstrap";
import ButtonCartoAction from "../../buttons/ButtonCartoAction";
import Contrat from "./Contrat";
import ModalNewContrat from "../../modal/ModalNewContrat";

export default function ContratSection() {
  const context = useContext(StoreContext);
  const [showModalNewContrat, setShowModalNewContrat] = useState(
    getQueryParam("newcontrat") === "true",
  );
  const [openedSection, setOpenedSection] = useState(
    getQueryParam("contrat") ?? "",
  );

  const toggle = (id) => {
    if (openedSection === id) {
      deleteQueryParam("contrat");
    } else {
      setQueryParam("contrat", id);
    }
    deleteQueryParam("completercontrat");
    setOpenedSection(openedSection === id ? "" : id);
  };

  const toggleModalNewContrat = () => {
    const hasIntentionValide =
      context.surfacesPotentielles.filter((i) => i.valide).length > 0;

    if (!hasIntentionValide) {
      createNotification(
        "error",
        "Erreur",
        "Votre exploitation ne comporte aucune intention de semis validée par un administrateur. Si vous n’avez pas d’intention renseignée, merci de saisir vos intentions de semis. Si votre intention n’a pas encore été validée, un administrateur s’occupera de votre dossier dans les plus brefs délais.",
        15000,
      );
      return;
    }

    if (showModalNewContrat) {
      deleteQueryParam("newcontrat");
    } else {
      setQueryParam("newcontrat", "true");
    }
    setShowModalNewContrat(!showModalNewContrat);
  };

  return (
    <>
      <Collapse isOpen={showModalNewContrat} mountOnEnter unmountOnExit>
        <ModalNewContrat
          isOpen={showModalNewContrat}
          toggle={() => {
            deleteQueryParam("newcontrat");
            setShowModalNewContrat(!showModalNewContrat);
          }}
        />
      </Collapse>
      <Section
        className={"subsection-header"}
        id={"contrat"}
        title={"Contrat de production"}
      >
        <RenderIf
          isTrue={
            context.contratsExploitation.length === 0 ||
            context.contratsExploitation.length ===
              context.contratsExploitation.filter((c) => c.abandon).length
          }
        >
          <ButtonCartoAction
            onClick={toggleModalNewContrat}
            libelle={"Saisir mon contrat"}
            style={{ marginBottom: 10 }}
          />
          <Row style={{ marginTop: 10 }}>
            <Col>
              <Alert fade={false} color={"danger"}>
                Contrat non renseignée en {context.millesime.idmillesime}
              </Alert>
            </Col>
          </Row>
        </RenderIf>
        <Accordion
          className={"section-accordion-carto"}
          open={openedSection}
          toggle={toggle}
        >
          {context.contratsExploitation.map((contrat, index) => (
            <Contrat
              contrat={contrat}
              key={index}
              onClickSelectParcelle={(idparcelle) => {
                context.carto.getParcellaireLayer().clearSelection();
                const parcelle = context.carto.parcellaire.find(
                  (parcelle) => parcelle.idparcelle == idparcelle,
                );
                context.carto.setParcelleSelected(parcelle);
                context.carto.getParcellaireLayer().toggleSelectionFeature(
                  context.carto
                    .getParcellaireLayer()
                    .getFeatures()
                    .find((feature) => feature.get("idparcelle") == idparcelle),
                );
              }}
              onClickZoomParcelle={(idparcelle) => {
                context.carto.zoomMap([
                  context.carto
                    .getParcellaireLayer()
                    .getFeatures()
                    .find((feature) => feature.get("idparcelle") == idparcelle),
                ]);
              }}
            />
          ))}
        </Accordion>
      </Section>
    </>
  );
}
