import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { createNotification, hash } from "../../config/utils";
import CommuneInput from "../CommuneInput";

export default function CreationAccountCard(props) {
  const navigate = useNavigate();
  const [isMultiExploitation, setIsMultiExploitation] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    const siretInput = event.target.siret.value
      .split("\n")
      .filter(function (value) {
        return value != null && value !== "";
      });
    siretInput.forEach((siret) => siret.replace(" ", ""));

    if (event.target.password.value !== event.target.repeatedpassword.value) {
      createNotification(
        "error",
        "Erreur",
        "Les mots de passes ne correspondent pas",
      );
    } else {
      const form = {
        nom: event.target.nom.value,
        prenom: event.target.prenom.value,
        telephone: event.target.telephone.value,
        mail: event.target.mail.value,
        password: hash(event.target.password.value),
        adresse: event.target.adresse.value,
        idcommune: event.target.idcommune.value,
        pays: "France",
        aboutme: event.target.aboutme.value,
        siret: siretInput,
      };
      await props.onClickValidate(form);
      props.onClickClose();
    }
  };

  return (
    <Card style={styles.card} className="card-login" key="loginCard">
      <CardHeader
        className="card-header-login"
        style={styles.cardHeader}
        key="loginCardHeader"
      >
        <CardTitle tag="h1">Création de votre compte</CardTitle>
        <p style={styles.alert}>
          Pour créer votre compte, merci de remplir ce formulaire.
          <br />
          Une fois le formulaire complété, un administrateur se chargera de
          valider la création de votre compte.
        </p>
      </CardHeader>
      <CardBody style={{ overflowX: "hidden" }}>
        <Form onSubmit={onSubmit}>
          <Row>
            <h5>Identité</h5>
            <Col md={4}>
              <Label for="nom">Nom</Label>
              <Input type="text" name="nom" placeholder="Nom" required />
            </Col>
            <Col md={4}>
              <Label for="prenom">Prénom</Label>
              <Input type="text" name="prenom" placeholder="Prénom" required />
            </Col>
            <Col md={4}>
              <Label for="telephone">Téléphone</Label>
              <Input
                type="text"
                name="telephone"
                placeholder="Téléphone"
                required
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <h5>Coordonnées</h5>
            <Col md="6">
              <FormGroup>
                <Label for="adresse">Adresse</Label>
                <Input
                  type="text"
                  name="adresse"
                  placeholder="Adresse"
                  required
                ></Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <CommuneInput codepostal={""} setIdCommune={() => {}} required />
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <h5>Informations de connexion</h5>
            <Col md="4">
              <FormGroup>
                <Label for="mail">Adresse mail</Label>
                <Input
                  type="email"
                  name="mail"
                  placeholder="Adresse mail"
                  required
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="password">Mot de passe</Label>
                <Input
                  type="password"
                  name="password"
                  placeholder="Mot de passe"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*?[0-9])(?=.*?[~`!@#$%\^&*()\-_=+[\]{};:\x27.,\x22\\|/?><]).{8,}"
                  required
                  title="8 caractères minimum, au moins une minuscule, une majuscule, un chiffre et un caractère spécial"
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="repeatedpassword">Répétez le mot de passe</Label>
                <Input
                  type="password"
                  name="repeatedpassword"
                  placeholder="Répétez le mot de passe"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*?[0-9])(?=.*?[~`!@#$%\^&*()\-_=+[\]{};:\x27.,\x22\\|/?><]).{8,}"
                  required
                  title="8 caractères minimum, au moins une minuscule, une majuscule, un chiffre et un caractère spécial"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <h5>Numéro SIRET</h5>
            <Col
              md="3"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <FormGroup check>
                <Input
                  id="multiexploitation"
                  name="check"
                  type="checkbox"
                  defaultValue={isMultiExploitation}
                  onChange={(event) =>
                    setIsMultiExploitation(event.target.checked)
                  }
                />{" "}
                <Label for="multiexploitation" check>
                  Multi-exploitations
                </Label>
              </FormGroup>
            </Col>
            <Col md="9">
              <FormGroup>
                <Input
                  type={isMultiExploitation ? "textarea" : "text"}
                  name="siret"
                  placeholder="Merci de renseigner le numéro de SIRET de votre exploitation."
                  required
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <h5>Commentaire</h5>
            <Col md="12">
              <FormGroup>
                <Input
                  type="textarea"
                  name="aboutme"
                  placeholder="Dites-nous en plus"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <Col>
              <Button color="primary" type="submit">
                Envoyer
              </Button>
            </Col>
            <Col>
              <Button color="danger" onClick={props.onClickClose}>
                Annuler
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <Col style={{ fontSize: 11 }}>
              En cliquant sur le bouton Envoyer, vous acceptez nos Conditions
              générales. <br />
              Pour obtenir plus d'informations sur les données que nous
              collectons, vous pouvez lire notre{" "}
              <a
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() =>
                  navigate("/auth/privacy-policy?redirectlogin=true")
                }
              >
                Politique de confidentialité
              </a>
              .
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

const styles = {
  card: {
    textAlign: "center",
    padding: 5,
    flexGrow: 1,
    height: "43em",
    maxWidth: "62em",
    flexDirection: "column",
  },
  cardBody: {
    marginTop: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  cardHeader: {
    lineHeight: 1.2,
    padding: "0rem 1rem",
  },
  alert: {
    fontSize: 12,
  },
  textField: {
    height: "1.5rem",
    padding: "0.75rem",
    borderRadius: 15,
    marginBottom: 20,
    fontSize: "1rem",
  },
  form: {
    display: "grid",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  logo: {
    paddingTop: 20,
  },
  button: {
    borderStyle: "none",
    fontSize: "1.25rem",
    lineHeight: 1.5,
    padding: "0.5rem 1rem",
    display: "block",
    width: "96%",
    color: "#ffffff",
    backgroundColor: "#495057",
    borderColor: "#007bff",
    borderRadius: 15,
    marginTop: 20,
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "96%",
    bottom: 5,
    color: "#ffffff",
    fontSize: 9,
  },
};
