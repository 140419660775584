import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import useConstructor from "../../config/hooks/useConstructor";
import OrganismeStockeurController from "../../config/apiUtils/OrganismeStockeurController";
import ProfilController from "../../config/apiUtils/ProfilController";
import UtilisateurController from "../../config/apiUtils/UtilisateurController";
import CloseButton from "../../components/Buttons/CloseButton";
import CommuneInput from "../../components/CommuneInput";
import StoreContext from "../../context/StoreContext";
import { createNotification, RenderIf } from "../../config/utils";

export default function FicheFormulaire(props) {
  const store = useContext(StoreContext);
  const [idprofil, setIdprofil] = useState(props.formulaire.idprofil ?? "-1");
  const [modal, setModal] = useState(false);
  const [showFormUpdate, setShowFormUpdate] = useState(false);
  const [organismes, setOrganismes] = useState([]);
  const [estorganismestockeur, setEstorganismestockeur] = useState(false);
  const [idorganismestockeur, setIdorganismestockeur] = useState(1);

  useConstructor(async () => {
    store.profils.setProfils(await ProfilController.getProfils());
    setOrganismes(await OrganismeStockeurController.getOrganismesStockeur());
  });

  useEffect(() => {
    if (store.profils.profils.length > 0) {
      const profil = store.profils.profils.find(
        (profil) => profil.idprofil == idprofil,
      );
      if (profil) {
        setEstorganismestockeur(profil.estorganismestockeur);
      }
    }
    if (idprofil == -1) {
      setEstorganismestockeur(false);
    }
  }, [idprofil]);

  const onClickValider = async (event) => {
    event.preventDefault();
    if (idprofil === "-1") {
      createNotification(
        "error",
        "Erreur",
        "Veuillez sélectionner un profil à associer à cet utilisateur.",
      );
      return;
    }
    const res = await UtilisateurController.validerFormulaireUtilisateur(
      props.formulaire.idformulaireutilisateur,
      idprofil,
      estorganismestockeur ? idorganismestockeur : null,
    );
    if (res.status !== 200) {
      createNotification("error", "Erreur", "Une erreur est survenue...");
    } else {
      createNotification("success", "Succès", "Formulaire validé");
    }
    await props.onClickClose();
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const form = {
      nom: event.target.nom.value,
      prenom: event.target.prenom.value,
      adresse: event.target.adresse.value,
      idcommune: event.target.idcommune.value,
    };
    const res = await UtilisateurController.updateFormulairesUtilisateur(
      props.formulaire.idformulaireutilisateur,
      form,
    );

    if (res.status === 200) {
      createNotification("success", "Succès", "Formulaire modifié");
      await props.loadFormulaires();
      setShowFormUpdate(false);
    } else {
      createNotification("error", "Erreur", "Une erreur est survenue...");
    }
  };

  const renderProfilsOptions = () => {
    return store.profils.profils.map((profil, key) => {
      return (
        <option value={profil.idprofil} key={key}>
          {profil.libelleprofil}
        </option>
      );
    });
  };

  const renderOrganismesStockeursOptions = () => {
    return organismes.map((organisme, key) => {
      return (
        <option value={organisme.idorganismestockeur} key={key}>
          {organisme.nomorganismestockeur}
        </option>
      );
    });
  };

  const renderDossiers = () => {
    if (props?.formulaire?.exploitations != null) {
      return props?.formulaire?.exploitations.map((exploitation, key) => {
        return (
          <tr key={key}>
            <td>{exploitation.raisonsociale}</td>
            <td>{exploitation.siret}</td>
          </tr>
        );
      });
    }
  };

  const renderDossiersSansExploitation = () => {
    if (props?.formulaire?.siret != null) {
      return props?.formulaire?.siret.map((siret, key) => {
        return (
          <tr key={key}>
            <td>Exploitation non présente en base</td>
            <td>{siret}</td>
          </tr>
        );
      });
    }
  };

  const renderFormUpdate = () => {
    // TODO ajouter on submit pour modification des informations du formulaire
    return (
      <Form style={{ marginBottom: 10 }} onSubmit={onSubmit}>
        <Row>
          <Col>
            <Table style={{ color: "#FFFFFF" }}>
              <tbody>
                <tr>
                  <th scope="row">Nom</th>
                  <td>
                    <FormGroup>
                      <Input
                        type="text"
                        name="nom"
                        placeholder="Nom"
                        defaultValue={props.formulaire.nom}
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Prénom</th>
                  <td>
                    <FormGroup>
                      <Input
                        type="text"
                        name="prenom"
                        placeholder="Prénom"
                        defaultValue={props.formulaire.prenom}
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Adresse</th>
                  <td>
                    <FormGroup>
                      <Input
                        type="text"
                        name="adresse"
                        placeholder="Adresse"
                        defaultValue={props.formulaire.adresse}
                      />
                    </FormGroup>
                    <FormGroup>
                      <CommuneInput
                        codepostal={props.formulaire.codepostal}
                        idcommune={props.formulaire.idcommune}
                        setIdCommune={() => {}}
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Adresse mail</th>
                  <td>{props.formulaire.mail}</td>
                </tr>
                <tr>
                  <th scope="row">Téléphone</th>
                  <td>{props.formulaire.telephone}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button color="primary" style={{ marginRight: 10 }} type="submit">
              Enregistrer
            </Button>
            <Button
              color="warning"
              style={{ marginRight: 10 }}
              onClick={() => setShowFormUpdate(false)}
            >
              Annuler
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  const removeFormulaireUtilisateur = async () => {
    const res = await UtilisateurController.deleteFormulaireUtilisateur(
      props.formulaire.idformulaireutilisateur,
    );
    if (res.status !== 200) {
      createNotification("error", "Erreur", "Une erreur est survenue...");
    } else {
      createNotification(
        "success",
        "Succès",
        "Formulaire supprimé avec succès",
      );
      await props.onClickClose();
    }
  };

  const renderModal = () => {
    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader>Supprimer un formulaire utilisateur</ModalHeader>
        <ModalBody>
          Vous demandez la suppression de la demande de création de compte de{" "}
          <b>
            {props.formulaire.prenom} {props.formulaire.nom}
          </b>
          .<br />
          <br />
          Confirmez-vous cette demande de suppression ?
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            autoFocus={true}
            onClick={removeFormulaireUtilisateur}
          >
            Confirmer
          </Button>{" "}
          <Button color="secondary" onClick={() => setModal(!modal)}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <>
      {renderModal()}
      <Card>
        <CardHeader>
          <CardTitle tag={"h5"}>
            Fiche - {props.formulaire.nom} {props.formulaire.prenom}
          </CardTitle>
          <CloseButton onClick={props.onClickClose} />
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <Collapse isOpen={!showFormUpdate} mountOnEnter unmountOnExit>
                <Table style={{ color: "#FFFFFF" }}>
                  <tbody>
                    <tr>
                      <th scope="row">Nom</th>
                      <td>
                        {props.formulaire.nom} {props.formulaire.prenom}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Adresse</th>
                      <td>
                        {props.formulaire.adresse}
                        <br />
                        {props.formulaire.codepostal}{" "}
                        {props.formulaire.nomcommune}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Adresse mail</th>
                      <td>{props.formulaire.mail}</td>
                    </tr>
                    <tr>
                      <th scope="row">Téléphone</th>
                      <td>{props.formulaire.telephone}</td>
                    </tr>
                    <tr>
                      <th scope="row">Date de la demande</th>
                      <td>{props.formulaire.datecreation}</td>
                    </tr>
                    <tr>
                      <th scope="row">Etat de la demande</th>
                      <td>{props.formulaire.libelleetatvalidation}</td>
                    </tr>
                  </tbody>
                </Table>
                <h5>Commentaires</h5>
                <Row style={{ marginBottom: 30 }}>
                  <Col>
                    <Input
                      type="textarea"
                      rows="3"
                      value={props.formulaire.aboutme}
                      readOnly
                    />
                  </Col>
                </Row>
              </Collapse>
              <Collapse isOpen={showFormUpdate} mountOnEnter unmountOnExit>
                {renderFormUpdate()}
              </Collapse>
            </Col>
            <Col md="6">
              <Row style={{ marginTop: 10 }}>
                <Col md={estorganismestockeur ? "6" : "12"}>
                  <FormGroup>
                    <Label for="profil">Profil utilisateur</Label>
                    <Input
                      type="select"
                      className="select-input"
                      name="profil"
                      value={idprofil}
                      onChange={(event) => {
                        setIdprofil(event.target.value);
                      }}
                      disabled={props.formulaire.idetatvalidation === 4}
                    >
                      <option value={"-1"}>--</option>
                      {renderProfilsOptions()}
                    </Input>
                  </FormGroup>
                </Col>
                <RenderIf isTrue={estorganismestockeur}>
                  <Col md={"6"}>
                    <FormGroup>
                      <Label for="idorganismestockeur">
                        Organisme stockeur
                      </Label>
                      <Input
                        type="select"
                        className="select-input"
                        value={idorganismestockeur}
                        onChange={(event) =>
                          setIdorganismestockeur(event.target.value)
                        }
                        name="idorganismestockeur"
                      >
                        {renderOrganismesStockeursOptions()}
                      </Input>
                    </FormGroup>
                  </Col>
                </RenderIf>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <h5>Accès demandés</h5>
                    <RenderIf
                      isTrue={
                        (props?.formulaire?.exploitations != null &&
                          props?.formulaire?.exploitations.length > 0) ||
                        (props?.formulaire?.siret != null &&
                          props?.formulaire?.siret.length > 0)
                      }
                    >
                      <Table
                        striped
                        className="table-affectation"
                        style={{
                          border: "1px solid white",
                          color: "#FFFFFF",
                          height: 250,
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Raison sociale</th>
                            <th>SIRET</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderDossiersSansExploitation()}
                          {renderDossiers()}
                        </tbody>
                      </Table>
                    </RenderIf>
                    <RenderIf
                      isTrue={
                        (props?.formulaire?.exploitations == null ||
                          props?.formulaire?.exploitations.length === 0) &&
                        (props?.formulaire?.siret == null ||
                          props?.formulaire?.siret.length === 0)
                      }
                    >
                      Pas d'exploitation demandée
                    </RenderIf>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <RenderIf
                isTrue={
                  !showFormUpdate && props.formulaire.idetatvalidation !== 4
                }
              >
                <Button
                  color="primary"
                  style={{ marginRight: 10 }}
                  onClick={async (event) => {
                    onClickValider(event);
                  }}
                >
                  Valider la demande
                </Button>
                <Button
                  color="info"
                  style={{ marginRight: 10 }}
                  onClick={() => setShowFormUpdate(true)}
                >
                  Modifier les informations
                </Button>
              </RenderIf>
              <Button
                color="danger"
                style={{ marginRight: 10 }}
                onClick={() => setModal(true)}
              >
                Supprimer le formulaire
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
