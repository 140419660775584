import APIController from "../APIController";

class DroitController extends APIController {
  constructor() {
    super("/droit");
  }

  async getDroitModalites() {
    return await this.getRequest("/modalite");
  }
}

export default new DroitController();
